// tslint:disable:variable-name
import { Injectable, OnDestroy } from '@angular/core';
import { Reservation } from '../_models/reservation.model';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import { FirestoreTableService } from '../../../_metronic/shared/crud-table/services/firestore-table.service';
import { ReservationModelMapper } from '../_mappers/reservation.model.mapper';
import { combineLatest, from, Observable, of } from 'rxjs';
import { ReservationStatusEnum } from '../_models/reservation-status.enum';
import { TableResponseModel } from '../../../_metronic/shared/crud-table';
import firebase from 'firebase';
declare type Query<T> = firebase.firestore.Query<T>;
import { IReservationsBaseFilter, IReservationsFilter, IReservationsTableState } from '../_interfaces/reservations-table-state.interface';
// import OrderByDirection = firebase.firestore.OrderByDirection;
// import { sortBy } from 'sort-by-typescript';
import {
    applyFilterByDateRange,
    filterByGroupRequiredOrThrow
} from './filter-by.utils';
import { ReservationsSynchServiceFactory } from './cross-platform-synch/reservations-synch-service.factory';
import { finalize, map, mergeAll } from 'rxjs/operators';
import { BulkSynchManagerService } from './cross-platform-synch/bulk-synch-manager.service';
import { IFilterByDateRange } from '../_interfaces/filter-by-date-range.interface';
import { collection } from 'rxfire/firestore';

interface ReservationStatusUpdate {
    status: ReservationStatusEnum;
    cancellationReason?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ReservationsTableService extends FirestoreTableService<Reservation, IReservationsFilter> implements OnDestroy {

    static readonly COLLECTION_NAME: string = 'reservations';

    constructor(
        private firestore: AngularFirestore,
        private reservationModelMapper: ReservationModelMapper,
        private reservationsSynchServiceFactory: ReservationsSynchServiceFactory,
        private bulkSynchManagerService: BulkSynchManagerService
    ) {
        super(firestore, reservationModelMapper, ReservationsTableService.COLLECTION_NAME);
    }

    /**
     * Find reservations
     *
     * @override
     * @param tableState new state for TableService
     * @throws Error if tableState.filter doesn't contains 'group' field
     */
    find(tableState: IReservationsTableState): Observable<TableResponseModel<Reservation>> {

        this._errorMessage.next('');
        this._isLoading$.next(true);

        // Get base filtering query
        let query = this.generateBaseFindQuery(tableState.filter);
        // query = query.orderBy(tableState.sorting.column, tableState.sorting.direction as firebase.firestore.OrderByDirection); // Exclude db sorting for now
        // .limit(tableState.paginator.pageSize); // Exclude pagination for now

        if ('status' in tableState.filter) {
            query = query.where('status', '==', tableState.filter.status);
        }

        if ('restaurants' in tableState.filter) {
            query = query.where('restaurant.slug', 'in', Array.from(tableState.filter.restaurants));
        }

        if ('dinerEmail' in tableState.filter) {
            query = query.where('diner.email', '==', tableState.filter.dinerEmail);
        }

        // Get results as stream
        const results$ = this.firestoreFindQueryToObservable(query);

        // Map results to TableResponseModel
        return this.toTableResponseModel(results$);
    }

    /**
     * Find reservations combining different queries
     *
     * @override
     * @protected
     * @param filters
     * @throws Error if tableState.filter doesn't contains 'group' field
     */
    protected findByMultipleFilters(filters: Partial<IReservationsBaseFilter>[]): Observable<TableResponseModel<Reservation>> {
        const observables = new Array<Observable<Reservation[]>>(); // Array of streams

        for (const filter of filters) {
            // Generate query
            const query = this.generateBaseFindQuery(filter);
            // push the result stream
            observables.push(
                this.firestoreFindQueryToObservable(query) // Get results as stream
            );
        }

        // Combine results streams into single stream
        const combined$ = combineLatest(observables).pipe(
            map(matrix => [].concat.apply([], matrix)), // from results[][] to results[]
        );

        // Map results to TableResponseModel
        return this.toTableResponseModel(combined$);
    }

    /**
     * Generate a Firestore query to find reservations filtered by IReservationsBaseFilter
     *
     * @private
     * @param filter
     * @throws Error if tableState.filter doesn't contains 'group' field
     */
    private generateBaseFindQuery(filter: Partial<IReservationsBaseFilter>): Query<DocumentData> {

        // If filter doesn't contains groupId field throw error
        filterByGroupRequiredOrThrow<IReservationsFilter>(filter);

        let query: firebase.firestore.Query<DocumentData> = this.collectionRef
            .where('groupId', '==', filter.groupId);

        // Apply filters

        if ('dateRange' in filter) {
            query = applyFilterByDateRange('reservationDate', filter as IFilterByDateRange, query);
        }

        if ('bookingProvider' in filter) {
            query = query.where('bookingProvider', '==', filter.bookingProvider);
        }

        if ('product' in filter) {
            query = query.where('product', '==', filter.product);
        }

        if ('timeAvailable' in filter) {
            query = query.where('reservationTimeAvailable', '==', filter.timeAvailable);
        }

        return query;
    }

    update(changes: Partial<Reservation>): Observable<Reservation> {

        this._isLoading$.next(true);

        return super.update(changes)
            .pipe(
                map((reservation) => {

                    if (changes.status === ReservationStatusEnum.Cancelled) {

                        this._isLoading$.next(true);

                        const service = this.reservationsSynchServiceFactory.getInstance(reservation.bookingProvider);

                        return service.cancel({
                            orderId: reservation.orderId,
                            reason: reservation.cancellationReason
                        }).pipe(
                            map(() => reservation),
                            finalize(() => this._isLoading$.next(false))
                        );
                    }

                    return of(reservation);
                }),
                mergeAll(),
            );
    }

    updateStatusForItems(reservations: Reservation[], newStatus: ReservationStatusUpdate): Observable<void> {

        this._isLoading$.next(true);

        const batch = this.db.batch();
        reservations.forEach((res) => {

            res.status = newStatus.status;
            res.cancellationReason = newStatus.cancellationReason;

            const docRef = this.collectionRef.doc(res.id);

            batch.update(docRef, newStatus);
        });

        return from(batch.commit()).pipe(
            map(() => {
                if (newStatus.status === ReservationStatusEnum.Cancelled) {

                    this._isLoading$.next(true);
                    return this.bulkSynchManagerService.cancel(reservations).pipe(
                        finalize(() => this._isLoading$.next(false))
                    );
                }

                return of(undefined);
            }),
            mergeAll(),
            finalize(() => this._isLoading$.next(false))
        );
    }

    groupReservations(reservations: Reservation[]): Observable<void> {

        this._isLoading$.next(true);

        const correlationId = this.db.collection('group-bookings').doc().id;

        const batch = this.db.batch();
        reservations.forEach((res, i) => {
            const docRef = this.collectionRef.doc(res.id);
            batch.update(docRef, {
                groupBookingId: correlationId,
                isMainBooking: res.isMainBooking
            });
        });

        return from(batch.commit()).pipe(
            finalize(() => this._isLoading$.next(false))
        );
    }

    delete(id: string): Observable<void> {
        const docRef = this.collectionRef.doc(id);

        return from(docRef.delete()).pipe(
            finalize(() => this._isLoading$.next(false))
        );
    }

    deleteItems(ids: string[]): Observable<void> {

        this._isLoading$.next(true);

        const batch = this.db.batch();
        ids.forEach((id) => {
            const docRef = this.collectionRef.doc(id);
            batch.delete(docRef);
        });

        return from(batch.commit()).pipe(
            finalize(() => this._isLoading$.next(false))
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }
}
