import {Injectable} from '@angular/core';
import {ReservationsSynchServiceFactory} from './reservations-synch-service.factory';
import {BookingProviderEnum} from '../../_models/booking-provider.enum';
import {Reservation} from '../../_models/reservation.model';
import {forkJoin, Observable} from 'rxjs';
import {ICancelRequest} from './interfaces/cancel-request.interface';

@Injectable({
    providedIn: 'root'
})
export class BulkSynchManagerService {

    constructor(private factory: ReservationsSynchServiceFactory) {}

    /**
     * Apply synch strategy for cancellation
     * @param reservations
     */
    cancel(reservations: Reservation[]): Observable<any>{

        // Split reservations by group to apply different strategies
        const grouped = this.splitByBookingProvider(reservations);

        const observables = new Array<Observable<any>>();

        grouped.forEach((value, key, map) => {
            // Get the service instance
            const service = this.factory.getInstance(key);

            const request = {
                reservations: reservations.map<ICancelRequest>((item) => {
                    return {
                        orderId: item.orderId,
                        reason: item.cancellationReason
                    };
                })
            };

            // Apply the strategy
            const obs = service.bulkCancel(request);

            observables.push(obs);
        });

        return forkJoin(observables);
    }

    /**
     * Split reservations into different set using booking provider as discriminant
     * @param reservations
     * @private
     */
    private splitByBookingProvider(reservations: Reservation[]): Map<BookingProviderEnum, Reservation[]>{

        // Init the map
        const splitted = new Map<BookingProviderEnum, Reservation[]>();

        for (const item of reservations){

            // Get the set
            const group = splitted.get(item.bookingProvider);

            // If set not exists then create it
            if (!splitted.get(item.bookingProvider)){
                splitted.set(item.bookingProvider, [item]);
            }
            else{
                // Add reservation to the set
                group.push(item);
            }
        }

        return splitted;
    }
}
