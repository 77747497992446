import firebase from 'firebase';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {IModelMapper} from '../../../_metronic/shared/crud-table/interfaces/model.mapper.interface';
import {Injectable} from '@angular/core';
import {EventModel} from '../_models/event.model';

@Injectable({
    providedIn: 'root'
})
export class EventModelMapper implements IModelMapper<EventModel>{
    /**
     * Map Firestore document snapshot into model
     *
     * @throws {Error} if document not exists
     */
    fromDocSnapshot(documentSnapshot: DocumentSnapshot): EventModel {
        if (!documentSnapshot.exists) {
            throw new Error('Document not exists');
        }

        const dto = documentSnapshot.data();

        return {
            id: documentSnapshot.id,
            groupId: dto.groupId,
            name: dto.name,
            date: dto.date.toDate(),
            eventTimeAvailable: dto.eventTimeAvailable ?? true,
            channelsMapping: dto.channelsMapping,
            tickets: dto.tickets,
            ticketsSold: 0,
            createdAt: dto.createdAt.toDate(),
            modifiedAt: dto.modifiedAt.toDate(),
            restaurant: dto.restaurant,
            alert: dto.alert,
        };
    }
}
