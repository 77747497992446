import firebase from 'firebase';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {IModelMapper} from '../../../_metronic/shared/crud-table/interfaces/model.mapper.interface';
import {Injectable} from '@angular/core';
import {BookingsAggregateByChannelModel} from '../_models/bookings-aggregate-by-channel.model';

@Injectable({
    providedIn: 'root'
})
export class BookingsAggregateByChannelModelMapper implements IModelMapper<BookingsAggregateByChannelModel>{
    /**
     * Map Firestore document snapshot into model
     *
     * @throws {Error} if document not exists
     */
    fromDocSnapshot(documentSnapshot: DocumentSnapshot): BookingsAggregateByChannelModel {
        if (!documentSnapshot.exists) {
            throw new Error('Document not exists');
        }

        const dto = documentSnapshot.data();

        return {
            id: documentSnapshot.id,
            groupId: dto.groupId,
            bookingProvider: dto.bookingProvider,
            product: dto.product,
            name: dto.product,
            eventDate: dto.eventDate.toDate(),
            eventTimeAvailable: dto.eventTimeAvailable ?? true,
            date: dto.eventDate.toDate(),
            restaurant: {
                name: dto.restaurant.name,
                slug: dto.restaurant.slug,
                address: dto.restaurant.address,
            },
            confirmedTickets: dto.confirmedTickets,
            pendingTickets: dto.pendingTickets,
            cancelledTickets: dto.cancelledTickets,
            pricePerPerson: dto.pricePerPerson,
            createdAt: dto.createdAt.toDate(),
            modifiedAt: dto.modifiedAt.toDate(),
        };
    }
}
