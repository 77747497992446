export enum BookingProviderEnum {
    Bottomlessbrunch = 'bottomlessbrunch',
    Designmynight = 'designmynight',
    Opentable = 'opentable',
    Fever = 'fever',
    Eventbrite = 'eventbrite',
    Airbnb = 'airbnb',
    Viator = 'viator',
    MyAirExperience = 'myairexperience',
    DirectReservation = 'direct_reservation',
    Other = 'other'
}
