export enum ReservationStatusEnum {
    Pending = 'pending',
    Confirmed = 'confirmed',
    Cancelled = 'cancelled',
    CancelledByDiner = 'cancelled_by_diner',
}

export const ReservationStatusEnumLabelMapping: Record<ReservationStatusEnum, string> = {
    [ReservationStatusEnum.Pending]: 'Pending',
    [ReservationStatusEnum.Confirmed]: 'Confirmed',
    [ReservationStatusEnum.Cancelled]: 'Cancelled',
    [ReservationStatusEnum.CancelledByDiner]: 'Cancelled by diner',
};
