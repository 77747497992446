export const isValidEmail = (emailString: string): boolean => {
    try {
        let pattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$");
        let valid = pattern.test(emailString);
        return valid;
    } catch (TypeError) {
        return false;
    }
}

export const isValidReferenceNo = (referenceNoString: string): boolean => {
    if (Number(referenceNoString) || referenceNoString.includes('-')) {
        return true;
    }
    return false;
}
