// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'staging',
  appVersion: 'v726demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  uiUrlStage1: 'https://tutti-poc.web.app',  // UI without new API (Still Using Firebase & Firestore)
  uiUrlStage2: 'https://booktutti-staging.web.app', // UI with new API
  apiUrl: 'https://europe-west2-tutti-crm-staging.cloudfunctions.net',
  isMockEnabled: true,
  firebase: {
    apiKey: 'AIzaSyBQ6EWFXS9gh4PpSGCJBll1WIBjgc6TPuo',
    authDomain: 'tutti-crm-staging.firebaseapp.com',
    projectId: 'tutti-crm-staging',
    storageBucket: 'tutti-crm-staging.appspot.com',
    messagingSenderId: '325137905502',
    appId: '1:325137905502:web:f56a0df5d4b03d9f3b01cf'
  },
  storageBucket: 'gs://booktutti-staging-uploads',
  useEmulators: false,
  sentry: {
    dsn: 'https://467c6b61854f4289bba7265fd873cd21@o551923.ingest.sentry.io/5977864',
  },
  mixpanel: {
    token: 'e42edc179960c7a943de6b1936c44b0b',
    config: {
      debug: true
    }
  },
  timeZone: 'Europe/London',
  bbApiUrl: 'https://bottomlessb.wpengine.com/wp-json',
  intercomAppId: 'cdyudzko'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
