import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  @Input() title = 'Important warning'; 
  @Input() message = '';
  @Input() showCancel = false;
  @Input() okTextMessage = 'Ok';
  @Input() okButtonClass = 'btn-danger';
  @Input() iconClass = 'text-danger';
  
  constructor(
    public readonly modal: NgbActiveModal
  ) { }

  ngOnInit(): void {

  }
}
