import {Directive, ElementRef, HostListener, Input, OnDestroy} from '@angular/core';
import {MixpanelService} from './mixpanel.service';
import {fromEvent, Subscription} from 'rxjs';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[mixpanelEvent]'
})
export class MixpanelDirective implements OnDestroy {

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
      private mixpanelService: MixpanelService,
      private elementRef: ElementRef
  ) { }

  @Input() mixpanelEvent: string;
  @Input() mixpanelData: any;

  @Input() set mixpanelBind(eventName: string){

    const sb = fromEvent(this.elementRef.nativeElement, eventName, {capture: true})
        .subscribe(() => this.trigger());
    this.unsubscribe.push(sb);
  }

  private trigger(): void {
    this.mixpanelService.track(this.mixpanelEvent, this.mixpanelData);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
