import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import {CurrentUserModel} from '../auth';

@Injectable()
export class MixpanelService {

  constructor() { }

  track(event: string, data?: any): void {
    mixpanel.track(event, data);
  }

  identify(currentUser: CurrentUserModel): void {
    mixpanel.identify(currentUser.id);
    mixpanel.alias(currentUser.email);
    mixpanel.people.set({
      role: currentUser.role,
      $name: currentUser.fullName,
      $email: currentUser.email
    });
  }

  reset(): void {
    mixpanel.reset();
  }
}
