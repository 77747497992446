import {Injectable, OnDestroy} from '@angular/core';
import {from, Observable, of, Subscription} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {map, mergeAll} from 'rxjs/operators';
import firebase from 'firebase';
import {CurrentUserModel} from '../..';
import {AngularFirestore} from '@angular/fire/firestore';
import UserCredential = firebase.auth.UserCredential;
import { AuthContextModel } from 'src/app/shared/models/security/auth-context.model';
import { AuthenticationUtil } from 'src/app/shared/utils/authentication-util';
import { GroupService } from 'src/app/modules/reservation-management/_services/group.service';
import { environment } from 'src/environments/environment';
import { Group } from 'src/app/modules/reservation-management/_models/group.model';

@Injectable({
    providedIn: 'root',
})
export class AuthFirebaseService implements OnDestroy {

    private unsubscribe: Subscription[] = [];

    constructor(
        private auth: AngularFireAuth,
        private groupService: GroupService,
        private firestore: AngularFirestore) { }

    login(email: string, password: string): Observable<CurrentUserModel> {
        return from(this.auth.signInWithEmailAndPassword(email, password))
            .pipe(
                map((res: UserCredential) => {
                    res.user.getIdToken().then((credential: string) => {
                        if (credential) {
                            AuthenticationUtil.setAuthContext(new AuthContextModel(credential));
                        }
                    });
                    return res.user
                }),
                map((u: firebase.User) => this.getUserById(u.uid)),
                mergeAll()
            );
    }

    getUserById(id: string): Observable<CurrentUserModel> {
        return this.firestore
            .doc<any>('users/' + id)
            .get()
            .pipe(
                map((doc) => {
                    if (!doc.exists) { throw new Error('Cannot find user'); }

                    const data = doc.data();

                    // NEW UI Redirection
                    if (data.group && data.group.id) {
                        this.checkUIRedirection(data.group.id);
                    }

                    return new CurrentUserModel(
                        doc.id,
                        data.firstName,
                        data.lastName,
                        data.email,
                        data.role,
                        data.group?.id
                    );
                })
            );
    }

    private checkUIRedirection(groupId: string): void {
        const sb = this.groupService.getById(groupId).subscribe(
            (group: Group) => {
                if (!group) {
                    return;
                }

                if (group.redirectToUIStage2) {
                    // Redirect to (NEW) UI with new API.
                    window.location.href = environment.uiUrlStage2;
                } else if (group.redirectToUIStage1 || group.showNewUi) {
                    // Redirect to (NEW) UI without new API (Still Using Firebase &Firestore)
                    window.location.href = environment.uiUrlStage1;
                }
            }
        );
        this.unsubscribe.push(sb);
    }

    logout(): Observable<void>{
        localStorage.removeItem('auth_context');
        return from(this.auth.signOut());
    }

    forgotPassword(email: string): Observable<boolean> {
        return from(this.auth.sendPasswordResetEmail(email))
            .pipe(
                map(() => true)
            );
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
