// tslint:disable:variable-name
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {ITableState, TableResponseModel} from '../models/table.model';
import {BaseModel} from '../models/base.model';
import {environment} from '../../../../../environments/environment';
import {TableService} from './table.service';

export abstract class RESTTableService<T extends BaseModel, TFilter> extends TableService<T, TFilter>{
    http: HttpClient;
    API_URL = `${environment.apiUrl}/endpoint`;  // API URL has to be overrided
    protected constructor(http: HttpClient) {
        super();
        this.http = http;
    }

    // READ (Returning filtered list of entities)
    find(tableState: ITableState<TFilter>): Observable<TableResponseModel<T>> {
        const url = this.API_URL + '/find';
        this._errorMessage.next('');
        return this.http.post<TableResponseModel<T>>(url, tableState).pipe(
            catchError(err => {
                this._errorMessage.next(err);
                console.error('FIND ITEMS', err);
                return of({items: [], total: 0});
            })
        );
    }

    getItemById(id: string): Observable<T> {
        this._isLoading$.next(true);
        this._errorMessage.next('');
        const url = `${this.API_URL}/${id}`;
        return this.http.get<T>(url).pipe(
            catchError(err => {
                this._errorMessage.next(err);
                console.error('GET ITEM BY IT', id, err);
                return of(null);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    // CREATE
    // server should return the object with ID
    create(item: T): Observable<T> {
        this._isLoading$.next(true);
        this._errorMessage.next('');
        return this.http.post<T>(this.API_URL, item).pipe(
            catchError(err => {
                this._errorMessage.next(err);
                console.error('CREATE ITEM', err);
                return of(null);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    // UPDATE
    update(item: Partial<BaseModel>): Observable<any> {
        const url = `${this.API_URL}/${item.id}`;
        this._isLoading$.next(true);
        this._errorMessage.next('');
        return this.http.patch(url, item).pipe(
            catchError(err => {
                this._errorMessage.next(err);
                console.error('UPDATE ITEM', item, err);
                return of(item);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    // UPDATE Status
    /* updateStatusForItems(ids: any[], status: any): Observable<any> {
        this._isLoading$.next(true);
        this._errorMessage.next('');
        const body = {ids, status};
        const url = this.API_URL + '/updateStatus';
        return this.http.patch(url, body).pipe(
            catchError(err => {
                this._errorMessage.next(err);
                console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
                return of([]);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    } */

    // DELETE
    delete(id: string): Observable<any> {
        this._isLoading$.next(true);
        this._errorMessage.next('');
        const url = `${this.API_URL}/${id}`;
        return this.http.delete(url).pipe(
            catchError(err => {
                this._errorMessage.next(err);
                console.error('DELETE ITEM', id, err);
                return of({});
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    // delete list of items
    deleteItems(ids: string[] = []): Observable<any> {
        this._isLoading$.next(true);
        this._errorMessage.next('');
        const url = this.API_URL + '/deleteItems';
        const body = {ids};
        return this.http.put(url, body).pipe(
            catchError(err => {
                this._errorMessage.next(err);
                console.error('DELETE SELECTED ITEMS', ids, err);
                return of([]);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }
}
