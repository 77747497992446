// tslint:disable:variable-name
import {BaseModel, GroupingState, ITableState, PaginatorState, SortState, TableResponseModel} from '..';
import {BehaviorSubject, Observable, of, pipe, Subscription} from 'rxjs';
import {catchError, finalize, take, tap} from 'rxjs/operators';

const defaultState = () => ({
    filter: {},
    paginator: new PaginatorState(),
    sorting: new SortState(),
    searchTerm: '',
    grouping: new GroupingState(),
});

export abstract class TableService<T extends BaseModel, TFilter> {

    // Private fields
    _items$ = new BehaviorSubject<T[]>([]);
    _isLoading$ = new BehaviorSubject<boolean>(false);
    _isFirstLoading$ = new BehaviorSubject<boolean>(true);
    _tableState$ = new BehaviorSubject<ITableState<TFilter>>(defaultState());
    _errorMessage = new BehaviorSubject<string>('');
    _subscriptions: Subscription[] = [];

    // Getters
    get items$(): Observable<T[]> {
        return this._items$.asObservable();
    }

    get isLoading$(): Observable<boolean> {
        return this._isLoading$.asObservable();
    }

    get isFirstLoading$(): Observable<boolean> {
        return this._isFirstLoading$.asObservable();
    }

    get errorMessage$(): Observable<string> {
        return this._errorMessage.asObservable();
    }

    get subscriptions(): Subscription[] {
        return this._subscriptions;
    }

    // State getters
    get paginator(): PaginatorState {
        return this._tableState$.value.paginator;
    }

    get filter() {
        return this._tableState$.value.filter;
    }

    get sorting(): SortState {
        return this._tableState$.value.sorting;
    }

    get searchTerm() {
        return this._tableState$.value.searchTerm;
    }

    get grouping() {
        return this._tableState$.value.grouping;
    }

    abstract find(tableState: ITableState<TFilter>): Observable<TableResponseModel<T>>;

    abstract getItemById(id: string): Observable<T>;

    abstract create(item: T): Observable<T>;

    abstract update(item: Partial<T>): Observable<T>;

    // abstract updateStatusForItems(ids: any[], status: any): Observable<any>;

    abstract delete(id: string): Observable<any>;

    abstract deleteItems(ids: string[]): Observable<any>;

    fetch(): Observable<TableResponseModel<T>> {
        this._isLoading$.next(true);
        this._errorMessage.next('');

        const response$ = this.patchStateOnResponse(
            this.find(this._tableState$.value)
        );

        const sb = response$.subscribe();
        this._subscriptions.push(sb);

        return response$;
    }

    /**
     * Patch the table state on request response
     *
     * @param response$
     * @protected
     */
    patchStateOnResponse(response$: Observable<TableResponseModel<T>>): Observable<TableResponseModel<T>>{
        return response$
            .pipe(
                tap((res: TableResponseModel<T>) => {
                    this._items$.next(res.items);
                    // Update pagination
                    this.patchStateWithoutFetch({
                        paginator: this._tableState$.value.paginator.recalculatePaginator(
                            res.total
                        ),
                    });

                    // temporary fix TODO: remove
                    this._tableState$.value.paginator.pageSize = res.total;
                }),
                take(1), // force complete (mmm.. nothing better?)
                finalize(() => {
                    this._isLoading$.next(false);
                    const itemIds = this._items$.value.map((el: T) => (el as BaseModel).id);
                    this.patchStateWithoutFetch({
                        grouping: this._tableState$.value.grouping.clearRows(itemIds),
                    });
                }),
                catchError((err) => {
                    this._errorMessage.next(err);
                    console.log(err);
                    return of({
                        items: [],
                        total: 0
                    });
                })
            );
    }

    setDefaults(): void {
        this.patchStateWithoutFetch({filter: {}});
        this.patchStateWithoutFetch({sorting: new SortState()});
        this.patchStateWithoutFetch({grouping: new GroupingState()});
        this.patchStateWithoutFetch({searchTerm: ''});
        this.patchStateWithoutFetch({
            paginator: new PaginatorState()
        });
        this._isFirstLoading$.next(true);
        this._isLoading$.next(true);
        this._tableState$.next(defaultState());
        this._errorMessage.next('');
        this._items$.next([]);
    }

    // Base Methods
    patchStateWithoutFetch(patch: Partial<ITableState<TFilter>>): void {
        const newState = Object.assign(this._tableState$.value, patch);
        this._tableState$.next(newState);
    }

    patchState(patch: Partial<ITableState<TFilter>>): Observable<TableResponseModel<T>> {
        this.patchStateWithoutFetch(patch);
        return this.fetch();
    }
}
