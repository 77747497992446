import { SearchTypeEnum } from "../../enums/search-type.enum";

export class ReservationSearchModel {

  constructor(groupId: string, type: SearchTypeEnum, value: string | string[]) {
    this.groupId = groupId;
    this.type = type;
    this.value = value;
  }

  groupId: string;
  type: SearchTypeEnum;
  value!: string | string[];
}
