// tslint:disable:variable-name
import { Injectable, OnDestroy } from '@angular/core';
import {AngularFirestore, DocumentData} from '@angular/fire/firestore';
import {FirestoreTableService} from '../../../_metronic/shared/crud-table/services/firestore-table.service';
import {Observable, of} from 'rxjs';
import {TableResponseModel} from '../../../_metronic/shared/crud-table';
import firebase from 'firebase';
import {
  applyFilterByDateRange,
  filterByGroupRequiredOrThrow
} from './filter-by.utils';
import {IReservationsFilter} from '../_interfaces/reservations-table-state.interface';
import {IFilterByDateRange} from '../_interfaces/filter-by-date-range.interface';
import {EventModel} from '../_models/event.model';
import {IEventsFilter, IEventsTableState} from '../_interfaces/events-table-state.interface';
import {EventModelMapper} from '../_mappers/event.model.mapper';

@Injectable({
  providedIn: 'root'
})
export class EventsTableService
    extends FirestoreTableService<EventModel, IEventsFilter> implements OnDestroy {

  static readonly COLLECTION_NAME: string = 'events';

  constructor(
      private firestore: AngularFirestore,
      private eventModelMapper: EventModelMapper
  ) {

    super(firestore, eventModelMapper, EventsTableService.COLLECTION_NAME);
  }

  /**
   * Find events
   *
   * @param tableState new state for TableService
   * @throws Error if tableState.filter doesn't contains 'restaurants' field
   */
  find(tableState: IEventsTableState): Observable<TableResponseModel<EventModel>> {

    this._errorMessage.next('');
    this._isLoading$.next(true);

    // If filter doesn't contains groupId field throw error
    filterByGroupRequiredOrThrow<IReservationsFilter>(tableState.filter);

    let query: firebase.firestore.Query<DocumentData> = this.collectionRef;
    // .orderBy(tableState.sorting.column, tableState.sorting.direction as OrderByDirection); // Exclude db sorting for now
    // .limit(tableState.paginator.pageSize); // Exclude pagination for now

    // Apply filters
    query = query.where('groupId', '==', tableState.filter.groupId);

    if ('dateRange' in tableState.filter) {
      query = applyFilterByDateRange('date', tableState.filter as IFilterByDateRange, query);
    }

    // Get results as stream
    const results$ = this.firestoreFindQueryToObservable(query);

    // Map results to TableResponseModel
    return this.toTableResponseModel(results$);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}

