import {IReservationsSynchService} from '../interfaces/reservations-synch-service.interface';
import {Observable, of} from 'rxjs';
import {ICancelRequest} from '../interfaces/cancel-request.interface';
import {IBulkCancelRequest} from '../interfaces/bulk-cancel-request.interface';

export class EmailReservationsSynchService implements IReservationsSynchService{

    constructor() {
    }

    cancel(request: ICancelRequest): Observable<any> {
        return of(undefined);
    }

    bulkCancel(request: IBulkCancelRequest): Observable<any> {
        return of(undefined);
    }
}
