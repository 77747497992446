import {APP_INITIALIZER, Inject, Provider} from '@angular/core';
import mixpanel from 'mixpanel-browser';
import {IMixpanelConfig, MIXPANEL_CONFIG_TOKEN} from './mixpanel.config';

/**
 * Provide a DI Configuration to attach Mixpanel Initialization at Angular Startup Cycle.
 */
export const MIXPANEL_INITIALIZER_PROVIDER: Provider = {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: mixpanelInitializer,
    deps: [
        MIXPANEL_CONFIG_TOKEN
    ]
};

export function mixpanelInitializer(mixpanelConfig: IMixpanelConfig): () => Promise<any>{

    return () => new Promise((resolve, reject) => {

        mixpanel.init(mixpanelConfig.token, mixpanelConfig.config);

        resolve();
    });
}
