import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EmailService } from '../../services/email.service';
import { EmailRequestModel } from '../../models/http/request/email-request.model';
import { RecipientModel } from '../../models/common/recipient.model';
import { ErrorModalComponent } from '../error-modal/error-modal.component';

enum EmailModeEnum {
  TEST,
  REAL
}

@UntilDestroy()
@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {

  @ViewChild('testEmailModalRef') testEmailModalRef!: TemplateRef<NgbModalRef>;
  @ViewChild('errorModalRef') errorModalRef!: TemplateRef<NgbModalRef>;
  @ViewChild('successModalRef') successModalRef!: TemplateRef<NgbModalRef>;

  @Input() recipients: RecipientModel[] = [];

  missingEmailCount = 0;

  to: string = 'To : ';
  testEmailForm: FormGroup;
  testEmailModal: NgbModalRef

  isEmailSending = false;
  isTestEmailSending = false;

  public Editor = ClassicEditor;
  editorConfig = {
    placeholder: 'Type some text...',
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'undo',
        'redo'
      ]
    }
  };

  subject = '';
  emailData = '';
  onPreview = false;

  constructor(
    private fb: FormBuilder,
    private mailService: EmailService,
    private modalService: NgbModal,
    public readonly modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.testEmailForm = this.fb.group({
      email: [null, [ Validators.required, Validators.email ] ]
    });
    this.initEmail();
  }

  initEmail() {
    const recipients = this.recipients.filter(item => item.email && item.email.trim().length);
    this.missingEmailCount = this.recipients.length - recipients.length;
    this.recipients = recipients;

    for(let i = 0; i < this.recipients.length; i++) {
      this.to = this.to + `${this.recipients[i].name} <${this.recipients[i].email}>; `;
      if (i === 1) {
        if (this.recipients.length > 2) {
          this.to = this.to + `and ${this.recipients.length - 2} more...`;
        } 
        break;
      }
    }
  }

  onPreviewSelected() {
    this.previewEmails();
  }

  previewEmails() {
    this.onPreview = true;
  }

  onOkSelected() {
    if (this.missingEmailCount > 0) {
      let message = '';
      if (this.missingEmailCount === 1) {
        message = `${this.missingEmailCount} diner does not have an e-mail address.`
      } else {
        message = `${this.missingEmailCount} diners do not have an e-mail address.`
      }

      const erorModalRef = this.modalService.open(ErrorModalComponent);
      erorModalRef.componentInstance.message = message;
      erorModalRef.componentInstance.showCancel = true;
      erorModalRef.componentInstance.okTextMessage = 'Send it anyway';
      erorModalRef.result.then(() => {
        this.sendEmails();
      }, () => {

      });
    } else {
      this.sendEmails();
    }
  }

  sendEmails() {
    this.isEmailSending = true;
    let emails = [];
    this.recipients.forEach(recipient => {
      emails = [...emails, recipient.email];
    });

    const emailRequestModal = new EmailRequestModel(emails, this.subject, this.emailData);
    this.mailService.send(emailRequestModal).pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isEmailSending = false;
        this.showSuccessModal(EmailModeEnum.REAL);
      }, err => {
        console.log('Error while sending Email. Error : ', err);
        this.isEmailSending = false;
        this.showErrorModal();
      });
  }

  onTestEmailBtnClicked() {
    this.testEmailModal = this.modalService.open(this.testEmailModalRef, { size: 'md' });
    this.testEmailModal.result.then(() => {

    },() => {

    });
  }

  sendTestEmail() {
    this.isTestEmailSending = true;
    const email = this.testEmailForm?.controls?.email?.value;
    if (!email || email.trim() === '') {
      return;
    }

    const emailRequestModal = new EmailRequestModel([email], this.subject, this.emailData);
    this.mailService.send(emailRequestModal).pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isTestEmailSending = false;
        this.showSuccessModal(EmailModeEnum.TEST);
      }, err => {
        console.log('Error while sending Test Email. Error : ', err);
        this.isTestEmailSending = false;
        this.showErrorModal();
      });
  }

  isFormValid(): boolean {
    if (!this.subject || this.subject.trim() === '') {
      return false;
    }

    if (!this.emailData || this.emailData.trim() === '') {
      return false;
    }

    return true;
  }

  showErrorModal() {
    this.modalService.open(this.errorModalRef, { size: 'md' });
  }

  showSuccessModal(mode: EmailModeEnum) {
    const modal = this.modalService.open(this.successModalRef, { size: 'md' });
    modal.result.then(() => {
      if (mode == EmailModeEnum.REAL) {
        this.modal.close();
      } else if (mode == EmailModeEnum.TEST) {
        this.testEmailModal.close();
      }
    },() => {

    });
  }
}
