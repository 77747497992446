import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validateFormGroup } from 'src/app/shared/utils/form.util';

import { ReservationSearchModel } from '../../models/common/reservation-search.model';
import { AuthService, UserRoleEnum } from 'src/app/modules/auth';
import { SearchTypeEnum } from '../../enums/search-type.enum';
import { isValidReferenceNo, isValidEmail } from '../../utils/validate.util';

@Component({
  selector: 'app-search-input-form',
  templateUrl: './search-input-form.component.html',
  styleUrls: ['./search-input-form.component.scss']
})
export class SearchInputFormComponent implements OnInit {

  static readonly SEARCH_RESULT_PAGE_URL = '/reservations/search';

  searchForm!: FormGroup;
  UserRoleEnum = UserRoleEnum;
  SearchTypeEnum = SearchTypeEnum;
  
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      parameter: [null, [ Validators.required ] ]
    });
  }

  onSeachFormSubmitted() {
    validateFormGroup(this.searchForm);
    if (this.searchForm.invalid) {
      this.clearSearchInput();
      return;
    }

    const currentUser = this.authService.currentUserValue;
    const searchValue = this.searchForm.controls?.parameter?.value?.trim();

    let searchType = SearchTypeEnum.NAME
    if (isValidEmail(searchValue)) {
      searchType = SearchTypeEnum.EMAIL;
    } else if (isValidReferenceNo(searchValue)) {
      searchType = SearchTypeEnum.REFERENCE;
    }
    
    // Searh reservations using search criteria.
    const search = new ReservationSearchModel(currentUser.groupId, searchType, searchValue);
    const navigationExtras: NavigationExtras = {
      state: {
        search
      },
      replaceUrl: true,
      skipLocationChange: false
    };
    this.routeToSearchPage(navigationExtras);
  }

  clearSearchInput() {
    this.searchForm.reset();
    this.routeToSearchPage(undefined);
  }

  routeToSearchPage(navigationExtras?: NavigationExtras) {
    if (this.router.url === SearchInputFormComponent.SEARCH_RESULT_PAGE_URL) {
      // Navigates to start page first to "destroy" detail components if on same url
      this.router.navigate(['/'], navigationExtras).then(() => {
        // Then navigates to desired url 
        this.router.navigate([SearchInputFormComponent.SEARCH_RESULT_PAGE_URL], navigationExtras);
      });
    } else {
      this.router.navigate([SearchInputFormComponent.SEARCH_RESULT_PAGE_URL], navigationExtras);
    }
  }
}
