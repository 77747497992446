<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- begin: Errors -->
  <div class="error">
    <i class="fas fa-exclamation-circle" [ngClass]="[iconClass]"> </i>
    <p>{{ message }}</p>
  </div>
  <!-- end: Errors -->
</div>
<div class="modal-footer">
  <ng-container *ngIf="showCancel">
    <button type="button" ngbAutofocus class="btn btn-light" (click)="modal.dismiss()">Cancel</button>
  </ng-container>
  <button type="button" ngbAutofocus class="btn" [ngClass]="[okButtonClass]" (click)="modal.close()">{{ okTextMessage }}</button>
</div>