import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// 3rd Party ::: Modules
import { NgxSpinnerModule } from "ngx-spinner";
import { InlineSVGModule } from 'ng-inline-svg';
import { AppPipesModule } from '../app-pipes.module';
import { AppDirectivesModule } from '../app-directives.module';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";

// 3rd Party ::: NgBootstrap Modules
import { NgbDatepickerModule, NgbModalModule, NgbModule, NgbProgressbarModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';

// Shared Components & directives
import { EmptyComponent } from './components/empty/empty.component';
import { SearchInputFormComponent } from './components/search-input-form/search-input-form.component';
import { DeleteReservationWarningModalComponent } from '../modules/reservation-management/common/reservations-table/components/delete-reservation-warning-modal/delete-reservation-warning-modal.component';
import { DeleteEventWarningModalComponent } from '../modules/reservation-management/group/components/events/components/delete-event-warning-modal/delete-event-warning-modal.component';
import { EmailModalComponent } from './modals/email-modal/email-modal.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';

const NG_BOOTSTRAP_MODULS = [
  NgbModule,
  NgbModalModule, 
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbProgressbarModule,
]

// ThirdParty Modules
const THIRDMODULES = [
  NgbModule,
  AppPipesModule,
  AppDirectivesModule,
  NgxSpinnerModule,
  InlineSVGModule,
  ClipboardModule,
  CKEditorModule,
  MatDatepickerModule,
  MatMomentDateModule,
  TranslateModule.forRoot(),
  ...NG_BOOTSTRAP_MODULS
];
// end::ThirdParty Modules

// Components & directives
const COMPONENTS: any[] = [
  EmptyComponent,
  SearchInputFormComponent,
  DeleteEventWarningModalComponent,
  DeleteReservationWarningModalComponent,
  EmailModalComponent,
  ErrorModalComponent
];

const DIRECTIVES: any[] = [

];
// end::Components & directives

// Pipes
const PIPES: any[] = [];
// End Pipes

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    // third libs
    ...THIRDMODULES
  ],
  declarations: [
    // components and directives
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    // third libs
    ...THIRDMODULES,
    // components and directives
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class SharedModule { }
