import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { CurrentUserModel } from '../_models/current-user.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {AuthFirebaseService} from './auth-firebase/auth-firebase.service';
import {MixpanelService} from '../../mixpanel/mixpanel.service';
import { GroupService } from '../../reservation-management/_services/group.service';
import { Group } from '../../reservation-management/_models/group.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private currentUserLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<CurrentUserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<CurrentUserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;


  get currentUserValue(): CurrentUserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: CurrentUserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
      private authFirebaseService: AuthFirebaseService,
      private router: Router,
      private groupService: GroupService,
      private mixpanelService: MixpanelService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<CurrentUserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    // const subscr = this.getUserByToken().subscribe();
    // this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<CurrentUserModel> {
    this.isLoadingSubject.next(true);

    return this.authFirebaseService.login(email, password).pipe(
      map((currentUser: CurrentUserModel) => {

        if (!currentUser || !currentUser.id) {
          return of(undefined);
        }

        this.currentUserSubject.next(currentUser);
        this.setCurrentUserFromLocalStorage(currentUser);

        this.mixpanelService.identify(currentUser);

        return currentUser;
      }),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {

    this.isLoadingSubject.next(true);

    localStorage.removeItem(this.currentUserLocalStorageToken);

    const subscription = this.authFirebaseService.logout().pipe(
        catchError((err) => {
          console.error('err', err);
          return err;
        }),
        finalize(() => this.isLoadingSubject.next(false))
    ).subscribe(() => {

      this.mixpanelService.reset();

      this.router.navigate(['/auth/login'], {
          queryParams: {},
      });

    });

    this.unsubscribe.push(subscription);
  }

  getLoggedInUser(): Observable<CurrentUserModel> {

    const currentUser = this.getCurrentUserFromLocalStorage();
    if (!currentUser || !currentUser.id) {
      return of(undefined);
    }

    // NEW UI Redirection
    this.checkUIRedirection(currentUser.groupId);

    this.isLoadingSubject.next(true);
    return this.authFirebaseService.getUserById(currentUser.id).pipe(
      map((user: CurrentUserModel) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  private checkUIRedirection(groupId: string): void {
    const sb = this.groupService.getById(groupId).subscribe(
      (group: Group) => {
        if (!group) {
          return;
        }

        if (group.redirectToUIStage2) {
          window.location.href = environment.uiUrlStage2;
      } else if (group.redirectToUIStage1 || group.showNewUi) {
          window.location.href = environment.uiUrlStage1;
      }
      }
    );
    this.unsubscribe.push(sb);
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authFirebaseService
      .forgotPassword(email)
      .pipe(
          finalize(() => this.isLoadingSubject.next(false))
      );
  }

  // private methods
  private setCurrentUserFromLocalStorage(currentUser: CurrentUserModel): boolean {
    // store currentUser in local storage to keep user logged in between page refreshes
    if (currentUser && currentUser.id) {
      localStorage.setItem(this.currentUserLocalStorageToken, JSON.stringify(currentUser));
      return true;
    }
    return false;
  }

  private getCurrentUserFromLocalStorage(): CurrentUserModel {
    try {
      const currentUser = JSON.parse(
        localStorage.getItem(this.currentUserLocalStorageToken)
      );
      return currentUser;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
