<!-- begin: No Data -->
<div class="no-result" [ngStyle]="{ 'display': isVisible ? 'block' : 'none'}">
    <div class="no-result__img">
        <img [src]="notFoundImage" alt="No Result" />
    </div>
    <div class="no-result__title">
        {{ notFoundContent }}
    </div>
    <div class="no-result__sub-title">
        {{ notFoundFooter }}
    </div>
</div>
<!-- end: No Data -->