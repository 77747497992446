import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { EmailRequestModel } from '../models/http/request/email-request.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private apiUrl = `${environment.apiUrl}/data/v1`;

  constructor(
    private http: HttpClient
  ) { }

  send(email: EmailRequestModel): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/mails/send`, email);
  }
}
