import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    private history: string[] = []
    // Maximum history limit.
    private maxHistoryLength = 10; 

    constructor() {

    }

    addUrlHistory(event: NavigationEnd): void {
        this.history.push(event.urlAfterRedirects);
        if (this.history.length > this.maxHistoryLength) {
            this.history.shift();
        }
    }

    getUrlHistory(): string[] {
        return this.history;
    }

    getPreviousPage(): string {
        return this.history.length > 1 ? this.history[this.history.length - 2]: '/';
    }
}
