import firebase from 'firebase';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {IModelMapper} from '../../../_metronic/shared/crud-table/interfaces/model.mapper.interface';
import {Group} from '../_models/group.model';
import {Injectable} from '@angular/core';
import {Restaurant} from '../_models/restaurant.model';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GroupModelMapper implements IModelMapper<Group>{
    /**
     * Map Firestore document snapshot into model
     *
     * @throws {Error} if document not exists
     */
    fromDocSnapshot(documentSnapshot: DocumentSnapshot): Group {
        if (!documentSnapshot.exists) {
            throw new Error('Document not exists');
        }

        const dto = documentSnapshot.data();

        return {
            id: documentSnapshot.id,
            name: dto.name,
            showNewUi: dto.showNewUi,
            redirectToUIStage1: dto.redirectToUIStage1,
            redirectToUIStage2: dto.redirectToUIStage2,
            restaurantsIds: dto.restaurants ? dto.restaurants.map(docRef => docRef.id) : [],
            restaurants: new Subject<Restaurant[]>()
        };
    }
}
