import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { EventsTableService } from 'src/app/modules/reservation-management/_services';

@UntilDestroy()
@Component({
  selector: 'app-delete-event-warning-modal',
  templateUrl: './delete-event-warning-modal.component.html',
  styleUrls: ['./delete-event-warning-modal.component.scss']
})
export class DeleteEventWarningModalComponent implements OnInit {

  @Input() ids: string[] = [];
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  reservations: Event[] = [];

  constructor(
    public readonly modal: NgbActiveModal,
    private eventsTableService: EventsTableService
  ) { }

  ngOnInit(): void {

  }

  onOkSelected() {
    this.deleteEvent();
  }

  deleteEvent() {
    this.loading.next(true);
    this.eventsTableService.deleteItems(this.ids).pipe(
      untilDestroyed(this),
      tap(() => this.modal.close()),
      catchError((errorMessage) => {
        console.log(errorMessage);
        this.modal.dismiss(errorMessage);
        return of(undefined);
      }),
      finalize(() => {
        this.loading.next(false);
      })
    ).subscribe();
  }
}
