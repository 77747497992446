export class EmailRequestModel {

    constructor(emails: string[], subject: string, body: string,) {
      this.emails = emails;
      this.subject = subject;
      this.body = body;
    }
  
    emails?: string[];
    subject?: string;
    body?: string;
  }