import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthenticationUtil } from '../shared/utils/authentication-util';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth token from the service.
    return from(AuthenticationUtil.getAuthContext()).pipe(
      mergeMap(authContext => {
        if (authContext && authContext.accessToken) {
          req = req.clone({ setHeaders: { Authorization: `Bearer ${authContext.accessToken}` } });
        }
        return next.handle(req);
      })
    )
  }
}
