import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplacePipe } from './pipes/replace.pipe';
import { SplitCamelCasePipe } from './pipes/split-camel-case.pipe';
import { DateWithTimezonePipe } from './pipes/date-with-timezone.pipe';

/**
 * @see https://angular.io/guide/sharing-ngmodules
 */
@NgModule({
  declarations: [
      ReplacePipe,
      SplitCamelCasePipe,
      DateWithTimezonePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
      ReplacePipe,
      SplitCamelCasePipe,
      DateWithTimezonePipe
  ]
})
export class AppPipesModule { }
