import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { Reservation } from 'src/app/modules/reservation-management/_models/reservation.model';
import { ReservationsTableService } from 'src/app/modules/reservation-management/_services';

@UntilDestroy()
@Component({
  selector: 'app-delete-reservation-warning-modal',
  templateUrl: './delete-reservation-warning-modal.component.html',
  styleUrls: ['./delete-reservation-warning-modal.component.scss']
})
export class DeleteReservationWarningModalComponent implements OnInit {

  @Input() id: string;
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  reservations: Reservation[] = [];

  constructor(
    public readonly modal: NgbActiveModal,
    private reservationsTableService: ReservationsTableService
  ) { }

  ngOnInit(): void {

  }

  onOkSelected() {
    this.deleteReservation();
  }

  deleteReservation() {
    this.loading.next(true);
    this.reservationsTableService.delete(this.id).pipe(
      untilDestroyed(this),
      tap(() => this.modal.close()),
      catchError((errorMessage) => {
        console.log(errorMessage);
        this.modal.dismiss(errorMessage);
        return of(undefined);
      }),
      finalize(() => {
        this.loading.next(false);
      })
    ).subscribe();
  }
}
