import {Config} from 'mixpanel-browser';
import {InjectionToken} from '@angular/core';

export interface IMixpanelConfig {
    token: string;
    config?: Partial<Config>;
    name?: string;
}

/**
 * Provide a Injection Token to global settings.
 */
export const MIXPANEL_CONFIG_TOKEN = new InjectionToken<IMixpanelConfig>('mixpanel-config');
