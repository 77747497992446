import firebase from 'firebase/app';

import { AuthContextModel } from "../models/security/auth-context.model";
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { CurrentUserModel, UserRoleEnum } from 'src/app/modules/auth';

export class AuthenticationUtil {

    private static currentUserLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    static async getAuthContext(): Promise<AuthContextModel | undefined> {
        const authContextString = localStorage.getItem('auth_context');
        if (authContextString) {
            try {
                let authContextModel: AuthContextModel = JSON.parse(authContextString);
                if (authContextModel && this.isTokenExpired(authContextModel)) {
                    const idToken =  await this.refreshToken();
                    if (idToken) {
                        authContextModel = new AuthContextModel(idToken);
                        this.setAuthContext(authContextModel);
                    }                    
                }
                return authContextModel;
            } catch (error) {
                console.log('Json parse error : Could not parse UserCredential as AuthContextModel!');
                return;
            }
        }
        return;
    }

    static setAuthContext(authContext: AuthContextModel) {
        localStorage.setItem('auth_context', JSON.stringify(authContext));
    }

    private static isTokenExpired(authContext: AuthContextModel): boolean {
        return new JwtHelperService().isTokenExpired(authContext.accessToken, 900);
    }

    private static async refreshToken(): Promise<string> {
        return await firebase.auth().currentUser.getIdToken(true);
    }

    static hasRole(role: UserRoleEnum): boolean {
        const currentUserString = localStorage.getItem(AuthenticationUtil.currentUserLocalStorageToken);
        if (currentUserString) {
            const currentUser: CurrentUserModel = JSON.parse(currentUserString);
            if (currentUser) {
                return role === currentUser.role;
            }
        }
        return false;
    }
}