import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckPermissionsDirective } from './directives/check-permissions.directive';
import { CheckNotPermissionsDirective } from './directives/check-not-permissions.directive';

/**
 * @see https://angular.io/guide/sharing-ngmodules
 */
@NgModule({
  declarations: [
      CheckPermissionsDirective,
      CheckNotPermissionsDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
      CheckPermissionsDirective,
      CheckNotPermissionsDirective
  ]
})
export class AppDirectivesModule {}
