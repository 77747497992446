<ng-container *appCurrentUserHasRole="[UserRoleEnum.Administrator, UserRoleEnum.EventManager]">
<!--begin::Search-->
<div class="search-input">
    <form [formGroup]="searchForm" (ngSubmit)="onSeachFormSubmitted()">        
        <div class="form-group">
            <span class="fa fa-search form-control-icon--start"></span>
            <input #searchInput type="text" formControlName="parameter" class="form-control" placeholder="Search bookings... Reference No, Email, Name etc." />
            <span *ngIf="searchInput.value" class="fa fa-times form-control-icon--end"
                (click)="clearSearchInput();"></span>
        </div>
    </form>
</div>
<!--end::Search-->
</ng-container>