import {Reservation} from '../_models/reservation.model';
import firebase from 'firebase';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {IModelMapper} from '../../../_metronic/shared/crud-table/interfaces/model.mapper.interface';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ReservationModelMapper implements IModelMapper<Reservation>{
    /**
     * Map Firestore document snapshot into model
     *
     * @throws {Error} if document not exists
     */
    fromDocSnapshot(documentSnapshot: DocumentSnapshot): Reservation {
        if (!documentSnapshot.exists) {
            throw new Error('Document not exists');
        }

        const dto = documentSnapshot.data();

        return {
            id: documentSnapshot.id,
            groupId: dto.groupId,
            restaurant: dto.restaurant,
            diner: dto.diner,
            status: dto.status,
            reservationDate: dto.reservationDate.toDate(),
            reservationTimeAvailable: dto.reservationTimeAvailable,
            orderDate: dto.orderDate.toDate(),
            orderId: dto.orderId,
            people: dto.people,
            pricePerPerson: dto.pricePerPerson,
            totalPaid: dto.totalPaid,
            product: dto.product,
            ticketType: dto.ticketType ?? null,
            manageReservationUrl: dto.manageReservationUrl,
            additionalInfo: dto.additionalInfo ?? '',
            bookingProvider: dto.bookingProvider,
            cancellationReason: dto.cancellationReason ?? '',
            checkoutOptions: dto.checkoutOptions ?? [],
            groupBookingId: dto.groupBookingId ?? null,
            isMainBooking: dto.isMainBooking ?? false,
            reservationSource: dto.reservationSource,
            createdAt: dto.createdAt.toDate(),
            modifiedAt: dto.modifiedAt.toDate(),
        };
    }
}
