import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {RestaurantModelMapper} from '../_mappers/restaurant.model.mapper';
import {Restaurant} from '../_models/restaurant.model';
import {collection} from 'rxfire/firestore';

@Injectable()
export class RestaurantsService {

    private readonly collectionReference;

    // tslint:disable-next-line:variable-name
    private _restaurants$ = new Subject<Restaurant[]>();
    get restaurants$(): Observable<Restaurant[]>{
        return this._restaurants$.asObservable();
    }

    // tslint:disable-next-line:variable-name
    private _isLoading$ = new BehaviorSubject<boolean>(false);
    get isLoading$(): Observable<boolean> {
        return this._isLoading$.asObservable();
    }

    constructor(
        private firestore: AngularFirestore,
        private restaurantModelMapper: RestaurantModelMapper
    ){
        this.collectionReference = this.firestore.firestore.collection('restaurants');
    }

    /**
     * Find restaurants
     *
     * @param groupId group Id
     */
    find(groupId: string): Observable<Restaurant[]> {
        this._isLoading$.next(true);

        const query = this.collectionReference.where('groupId', '==', groupId);

        return collection(query)
            .pipe(
                map(docs => docs.map(doc => this.restaurantModelMapper.fromDocSnapshot(doc))),
                tap((restaurants: Restaurant[]) => this._restaurants$.next(restaurants)),
                catchError(err => {
                    // this._errorMessage.next(err);
                    console.error(err);
                    return of([]);
                }),
                finalize(() => this._isLoading$.next(false)),
            );

    }
}
