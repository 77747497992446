// tslint:disable:variable-name
// import { environment } from '../../../../environments/environment';
import {TableService} from './table.service';
import {BaseModel, ITableState, SortState, TableResponseModel} from '..';
import {from, Observable, of} from 'rxjs';
import {AngularFirestore, CollectionReference, DocumentData} from '@angular/fire/firestore';
import { collection } from 'rxfire/firestore';
import {catchError, finalize, map, mergeAll, tap} from 'rxjs/operators';
// @ts-ignore
import {IModelMapper} from '../interfaces/model.mapper.interface';
import {sortBy} from 'sort-by-typescript';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import Firestore = firestore.Firestore;
import {Reservation} from '../../../../modules/reservation-management/_models/reservation.model';

export abstract class FirestoreTableService<T extends BaseModel, TFilter> extends TableService<T, TFilter> {

    protected readonly db: Firestore;
    protected readonly collectionRef: CollectionReference;

    protected constructor(private _firestore: AngularFirestore, private _modelMapper: IModelMapper<T>, readonly collectionName: string) {
        super();
        this.db = _firestore.firestore;
        this.collectionRef = this.db.collection(collectionName);
    }

    protected findByMultipleFilters(filters: Partial<TFilter>[]): Observable<TableResponseModel<Reservation>> {
        throw new Error('You should provide your custom implementation');
    }

    /**
     * Find items by id
     * @param ids
     */
    private findByIds(ids: string[]): Observable<TableResponseModel<T>> {
        this._isLoading$.next(true);
        this._errorMessage.next('');

        let results$ = of([]);

        if (ids.length > 0) {
            const query =  this.collectionRef.where(firestore.FieldPath.documentId(), 'in', ids);
            results$ = this.firestoreFindQueryToObservable(query);
        }

        return this.toTableResponseModel(results$);
    }

    fetch(): Observable<TableResponseModel<T>> {
        this._isLoading$.next(true);
        this._errorMessage.next('');

        const state = this._tableState$.value;

        let response$ = of({
            items: [],
            total: 0
        });
        if (state.ids) {
            response$ = this.findByIds(state.ids);
        }
        else if (state.multipleFilters) {
            response$ = this.findByMultipleFilters(state.multipleFilters);
        }
        else if (Object.keys(state.filter).length > 0){
            response$ = this.find(state);
        }

        const sb = this.patchStateOnResponse(response$).subscribe();
        this._subscriptions.push(sb);

        return response$;
    }

    /**
     * Return a stream of documents of type T
     *
     * @param query
     * @protected
     */
    protected firestoreFindQueryToObservable(query): Observable<T[]> {
        return collection(query)
            .pipe(
                map(docs => docs.map(docSnap => this._modelMapper.fromDocSnapshot(docSnap)))
            );
    }

    /**
     * Map find result to TableResponseModel
     * @param results
     * @protected
     */
    protected toTableResponseModel(results$: Observable<T[]>): Observable<TableResponseModel<T>>{
        return results$
            .pipe(
                map(items => ({
                        items,
                        total: items.length
                    })
                ), // TODO get data set size
              /* catchError(err => {
                  this._errorMessage.next(err);
                  console.error('FIND ITEMS', err);
                  return of({
                      items: [],
                      total: 0
                  });
              }),
              finalize(() => this._isLoading$.next(false))*/
          );
  }

    getItemById(id: string): Observable<T> {
        this._isLoading$.next(true);
        this._errorMessage.next('');

        return this._firestore
            .doc(this.collectionName + '/' + id)
            .get()
            .pipe(
                map(doc => this._modelMapper.fromDocSnapshot(doc)),
                catchError(err => {
                    this._errorMessage.next(err);
                    console.error('GET ITEM BY ID', id, err);
                    return of(null);
                }),
                finalize(() => this._isLoading$.next(false))
            );
    }

    create(item: T): Observable<T> {
        this._isLoading$.next(true);
        this._errorMessage.next('');

        const docRef = this.collectionRef.doc();
        item.id = docRef.id;

        return from(docRef.set(item))
          .pipe(
              map(() => item),
              catchError(err => {
                  this._errorMessage.next(err);
                  console.error('CREATE ITEM', item, err);
                  throw err;
              }),
              finalize(() => this._isLoading$.next(false))
          );
    }

    update(item: Partial<T>): Observable<T> {
        this._isLoading$.next(true);
        this._errorMessage.next('');

        return from(
            this.db.doc(this.collectionName + '/' + item.id).update(item)
        ).pipe(
            map(() => this.getItemById(item.id)),
            mergeAll(),
            catchError(err => {
                this._errorMessage.next(err);
                console.error('UPDATE ITEM', item, err);
                throw err;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    delete(id: string): Observable<void> {
        const docRef = this.collectionRef.doc(id);

        return from(docRef.delete()).pipe(
            finalize(() => this._isLoading$.next(false))
        );
    }

    deleteItems(ids: string[]): Observable<void> {
        this._isLoading$.next(true);

        const batch = this.db.batch();
        ids.forEach((id) => {
            const docRef = this.collectionRef.doc(id);
            batch.delete(docRef);
        });

        return from(batch.commit()).pipe(
            finalize(() => this._isLoading$.next(false))
        );
    }

    // Temporary sort items locally
    sortFetchedItems(sorting: SortState){
    const items = this._items$.value;

    // @see https://github.com/bameyrick/sort-by-typescript#sortbyprop-prop
    items.sort(sortBy(
        (sorting.direction === 'desc' ? '-' : '') + sorting.column + '^'
    ));

    this._items$.next(items);

    this.patchStateWithoutFetch({ sorting });
  }
}
