// Services
// export { ReservationsService } from './fake/reservations.service'; // You have to comment this, when your real back-end is done
export { ReservationsTableService } from './reservations-table.service'; // You have to uncomment this, when your real back-end is done

// tslint:disable-next-line:max-line-length
export { BookingsAggregatesByChannelTableService } from './bookings-aggregates-by-channel-table.service'; // You have to uncomment this, when your real back-end is done

export { EventsTableService } from './events-table.service'; // You have to uncomment this, when your real back-end is done

export { RestaurantsService } from './restaurants.service'; // You have to uncomment this, when your real back-end is done
