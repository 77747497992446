import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentData} from '@angular/fire/firestore';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, finalize, map, mergeAll, tap} from 'rxjs/operators';
import {Group} from '../_models/group.model';
import {GroupModelMapper} from '../_mappers/group.model.mapper';

@Injectable({
    providedIn: 'root'
})
export class GroupService {

    // tslint:disable-next-line:variable-name
    private _isLoading$ = new BehaviorSubject<boolean>(false);
    get isLoading$(): Observable<boolean> {
        return this._isLoading$.asObservable();
    }

    // tslint:disable-next-line:variable-name
    private _group = new BehaviorSubject<Group>(null);
    get group$(): Observable<Group>{
        return this._group.asObservable();
    }

    constructor(
        private firestore: AngularFirestore,
        private groupModelMapper: GroupModelMapper
    ){}


    getById(id: string): Observable<Group>{
        this._isLoading$.next(true);

        return this.firestore.doc('groups/' + id)
            .get()
            .pipe(
                map(docSnap => this.groupModelMapper.fromDocSnapshot(docSnap)),
                tap(group => this._group.next(group)),
                catchError(err => {
                    console.error('GET GROUP BY ID', id, err);
                    return of(null);
                }),
                finalize(() => this._isLoading$.next(false))
            );
    }
}
