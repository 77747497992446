<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Email</h4>
  <span type="button"aria-label="Close" (click)="modal.dismiss()">x</span>
</div>

<div class="modal-body">
  <div class="email">
    <ng-container *ngIf="!onPreview">
      <div class="email__template">
        <div class="form-group">
          <input type="text" class="form-control form-control-lg" placeholder="To"
            autocomplete="off" [(ngModel)]="to" [disabled]="true"/>
        </div>
        <div class="form-group">
          <input type="text" class="form-control form-control-lg" placeholder="Subject"
            autocomplete="off" [(ngModel)]="subject" />
        </div>
        <div class="form-group">
          <ckeditor [editor]="Editor" [(ngModel)]="emailData" [config]="editorConfig"></ckeditor>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="onPreview">
      <!-- begin:::Editor output -->
      <div class="email__preview">
        <div class="email__preview__alert">
          <ngb-alert [dismissible]="false" [type]="'success'">
            <strong>This message will be sent to a total of {{ recipients.length }} ticket holders.</strong>
          </ngb-alert>
        </div>
        
        <div class="email__preview__header text-right">
          <button type="button" class="btn btn-primary btn-sm font-weight-bolder font-size-sm"
            (click)="onTestEmailBtnClicked()">Send Test Email</button>
        </div>
        <div class="email__preview__subject">
          <div [innerHtml]="subject"></div>
        </div>
        <hr/>
        <div class="email__preview__body">
          <div [innerHtml]="emailData"></div>
        </div>
      </div>
      <!-- end:::Editor output -->
    </ng-container>
  </div>
</div>

<div class="modal-footer">
  <ng-container *ngIf="isEmailSending">
    <span class="spinner spinner-primary mr-10"></span>
  </ng-container>

  <button type="button" class="btn btn-light btn-elevate mr-3" (click)="modal.dismiss()">Cancel</button>

  <ng-container *ngIf="!onPreview">
    <button type="button" class="btn btn-primary font-weight-bolder font-size-sm" (click)="onPreviewSelected()"
    [disabled]="!isFormValid()">Preview</button>
  </ng-container>

  <ng-container *ngIf="onPreview">
    <button type="button" class="btn btn-secondary font-weight-bolder font-size-sm" (click)="onPreview = !onPreview">Update text</button>
  </ng-container>
  
  <ng-container *ngIf="onPreview">
    <button type="button" class="btn btn-primary font-weight-bolder font-size-sm" (click)="onOkSelected()">Send</button>
  </ng-container>
</div>

<ng-template #testEmailModalRef let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Send Test Email</h4>
    <span type="button"aria-label="Close" (click)="modal.dismiss()">x</span>
  </div>
  <div class="modal-body">
    <form [formGroup]="testEmailForm" class="form" novalidate="novalidate">
      <input type="text" class="form-control form-control-lg" formControlName="email" placeholder="Email" autocomplete="off" />
    </form>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="isTestEmailSending">
      <span class="spinner spinner-primary mr-10"></span>
    </ng-container>
    <button type="button" class="btn btn-light btn-elevate mr-3" (click)="modal.dismiss()">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-primary" (click)="sendTestEmail()"
    [disabled]="testEmailForm.invalid">Send</button>
  </div>
</ng-template>

<ng-template #errorModalRef let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Email</h4>
  </div>
  <div class="modal-body">
    <!-- begin: Errors -->
    <div class="error">
      <i class="fas fa-exclamation-circle text-danger"></i>
      <p>An error occurred while sending e-mail. Please try again later.</p>
    </div>
    <!-- end: Errors -->
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close()">Ok</button>
  </div>
</ng-template>

<ng-template #successModalRef let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Email</h4>
  </div>
  <div class="modal-body">
    <!-- begin: Success -->
    <div class="success">
      <i class="fas fa-check-circle text-primary"></i>
      <p>Your e-mail has been sent successfully.</p>
    </div>
    <!-- end: Success -->
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close()">Ok</button>
  </div>
</ng-template>