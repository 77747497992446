import {Query} from '@angular/fire/firestore';
import {IFilterByRestaurant} from '../_interfaces/filter-by-restaurant.interface';
import {IFilterByDateRange} from '../_interfaces/filter-by-date-range.interface';
import {combineLatest, Observable} from 'rxjs';
import {collection} from 'rxfire/firestore';
import {map} from 'rxjs/operators';
import firebase from 'firebase';
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {BaseModel} from '../../../_metronic/shared/crud-table';
import {IFilterByGroup} from '../_interfaces/filter-by-group.interface';

/**
 * If filter doesn't contains groupId field throw error
 * @param filter to check
 * @throws Error if tableState.filter doesn't contains 'groupId' field
 */
export function filterByGroupRequiredOrThrow<T extends IFilterByGroup>(filter: Partial<T>): void {
    if (!('groupId' in filter)) {
        throw new Error('Find reservations filter doesn\'t contains groupId field');
    }
}

/**
 * If filter doesn't contains restaurants field throw error
 * @param filter to check
 * @throws Error if tableState.filter doesn't contains 'restaurants' field
 */
export function filterByRestaurantRequiredOrThrow<T extends IFilterByRestaurant>(filter: Partial<T>): void {
    if (!('restaurants' in filter)) {
        throw new Error('Find reservations filter doesn\'t contains restaurants field');
    }
}

export function applyFilterByDateRange(field: string, filter: IFilterByDateRange, query: Query): Query{

    query = query
        .orderBy(field)
        .startAt(filter.dateRange.startDate)
        .endAt(filter.dateRange.endDate);

    return query;
}

/**
 * Split the Firestore where clause on array values and combine results
 *
 * This function is workaround because the Firestore clauses 'in', 'not-in' and 'array-contains-any' work only with 10 elements
 * @see https://firebase.google.com/docs/firestore/query-data/queries#array_membership
 *
 * @param modelMapper function to map Firestore document to a model
 * @param baseQuery a started Firestore query
 * @param fieldPath document field path
 * @param values to search for
 * @param operator comparison operator to apply, default '=='
 */
export function applyFilterByArrayValues<T extends BaseModel>(
    modelMapper: (documentSnapshot: DocumentSnapshot) => T,
    baseQuery: Query,
    fieldPath: string,
    values: any[],
    operator: WhereFilterOp = '=='
): Observable<T[]> {

    const observables = new Array<Observable<T[]>>();

    for (const value of values){
        const query = baseQuery.where(fieldPath, operator, value);
        const obs = collection(query)
            .pipe(
                map(docs => docs.map(d => modelMapper(d))), // Map results in models
            );
        observables.push(obs);
    }

    // Combine requests
    return combineLatest(observables).pipe(
        map(matrix => [].concat.apply([], matrix)) // from results[][] to results[]
    );
}
