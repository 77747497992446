import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MixpanelDirective } from './mixpanel.directive';
import {MIXPANEL_INITIALIZER_PROVIDER} from './mixpanel.initializer';
import {MixpanelService} from './mixpanel.service';
import {IMixpanelConfig, MIXPANEL_CONFIG_TOKEN} from './mixpanel.config';

@NgModule({
  declarations: [MixpanelDirective],
  imports: [
    CommonModule
  ],
  exports: [
    MixpanelDirective
  ]
})
export class MixpanelModule {

  static forRoot(mixpanelConfig: IMixpanelConfig): ModuleWithProviders<MixpanelModule> {
    return {
      ngModule: MixpanelModule,
      providers: [
        {
          provide: MIXPANEL_CONFIG_TOKEN,
          useValue: mixpanelConfig
        },
        MIXPANEL_INITIALIZER_PROVIDER,
        MixpanelService
      ]
    };
  }
}
