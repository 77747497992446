import {Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService, UserRoleEnum} from '../modules/auth';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appCurrentUserHasRole]'
})
export class CheckPermissionsDirective implements OnDestroy {

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef,
      private authService: AuthService
  ) {}

  @Input() set appCurrentUserHasRole(roles: UserRoleEnum | UserRoleEnum[]){

    const sb = this.authService.currentUser$.subscribe((currentUser) => {
      roles = Array.isArray(roles) ? roles : [roles];
      const role = roles.find(item => currentUser.role === item);
      if (role) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
      else{
        this.viewContainer.clear();
      }
    });

    this.unsubscribe.push(sb);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
