import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements AfterContentChecked {

  isVisible = false;
  @Input() notFoundImage: string = './assets/media/no-result/no-result-1.webp';
  @Input() notFoundContent?: string = 'No data found!';
  @Input() notFoundFooter?: string;

  isContentString = false;

  constructor(
    private cd: ChangeDetectorRef
  ) { }

  ngAfterContentChecked(): void {
    this.isVisible = true;
    this.cd.markForCheck();
    setTimeout(() => {
      this.cd.markForCheck();
    }, 500);
  }
}
