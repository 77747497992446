import firebase from 'firebase';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {IModelMapper} from '../../../_metronic/shared/crud-table/interfaces/model.mapper.interface';
import {Restaurant} from '../_models/restaurant.model';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RestaurantModelMapper implements IModelMapper<Restaurant>{
    /**
     * Map Firestore document snapshot into model
     *
     * @throws {Error} if document not exists
     */
    fromDocSnapshot(documentSnapshot: DocumentSnapshot): Restaurant {
        if (!documentSnapshot.exists) {
            throw new Error('Document not exists');
        }

        const dto = documentSnapshot.data();

        return {
            id: documentSnapshot.id,
            groupId: dto.groupId,
            name: dto.name,
            address: dto.address,
        };
    }
}
