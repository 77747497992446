import { Component, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { NavigationService } from './services/navigation.service';
import { TranslationService } from './modules/i18n/translation.service';
import { RESTTableExtendedService } from './_metronic/shared/crud-table';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Intercom } from 'ng-intercom';
import { environment } from 'src/environments/environment';

// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { AuthService } from './modules/auth';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: RESTTableExtendedService,
    private navigationService: NavigationService,
    public intercom: Intercom,
    private authService: AuthService

  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
    );
  }

  ngOnInit() {
    this.intercom.boot({
      app_id: environment.intercomAppId
    });

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // Push URL information to Navigation Service history.
        this.navigationService.addUrlHistory(event);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
      
      // Intercom settings
      const currentUser = this.authService.currentUserValue;
      if (currentUser) {
        this.intercom.update({
          name: currentUser.firstName,
          emain: currentUser.email
        });
      } else {
        this.intercom.update({
          name: null,
          emain: null
        });
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
