import {IReservationsSynchService} from './interfaces/reservations-synch-service.interface';
import {BookingProviderEnum} from '../../_models/booking-provider.enum';
import {BbReservationsSynchServices} from './bottomlessbrunch';
import {EmailReservationsSynchService} from './others/email-reservations-synch.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ReservationsSynchServiceFactory{

    constructor(private http: HttpClient) {}

    /**
     * Get the right implementation of IReservationsSynchService
     * @param bookingProvider discriminant
     */
    getInstance(bookingProvider: BookingProviderEnum): IReservationsSynchService {

        let instance: IReservationsSynchService;

        switch (bookingProvider) {
            case BookingProviderEnum.Bottomlessbrunch:
                instance = new BbReservationsSynchServices(this.http);
                break;
            default:
                instance = new EmailReservationsSynchService();
        }

        return instance;
    }
}
