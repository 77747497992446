// import { AuthModel } from './auth.model';
// import { AddressModel } from './address.model';
// import { SocialNetworksModel } from './social-networks.model';

import {UserRoleEnum} from './user-role.enum';

export class CurrentUserModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRoleEnum;
  groupId?: string;
  pic: string;
  // username: string;
  // password: string;
  // companyName: string;
  // phone: string;
  // address?: AddressModel;
  // socialNetworks?: SocialNetworksModel;
  // personal information

  // website: string;
  // account information
  // language: string;
  // timeZone: string;
  /*communication: {
    email: boolean,
    sms: boolean,
    phone: boolean
  };*/
  // email settings
  /*emailSettings: {
    emailNotification: boolean,
    sendCopyToPersonalEmail: boolean,
    activityRelatesEmail: {
      youHaveNewNotifications: boolean,
      youAreSentADirectMessage: boolean,
      someoneAddsYouAsAsAConnection: boolean,
      uponNewOrder: boolean,
      newMembershipApproval: boolean,
      memberRegistration: boolean
    }
  };*/

  constructor(
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      role: UserRoleEnum,
      groupId?: string,
      pic?: string
  ) {
    this.id = id;
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.email = email || '';
    this.role = role || UserRoleEnum.Guest;
    this.groupId = groupId;
    this.pic = pic || './assets/media/users/default.jpg';
  }

  get fullName(): string{
    return this.firstName + ' ' + this.lastName;
  }

  hasRole(role: UserRoleEnum): boolean{
    return this.role === role;
  }
}
