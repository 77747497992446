import {IReservationsSynchService} from '../interfaces/reservations-synch-service.interface';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {IBulkCancelRequest} from '../interfaces/bulk-cancel-request.interface';
import {ICancelRequest} from '../interfaces/cancel-request.interface';
import * as Sentry from '@sentry/angular';

export class BbReservationsSynchServices implements IReservationsSynchService{
    API_URL = `${environment.bbApiUrl}/reservation/v1`;

    _isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) {}

    get isLoading$(): Observable<boolean> {
        return this._isLoading$.asObservable();
    }

    /**
     * Synch the cancellation of multiple reservation with BB platform
     */
    bulkCancel(request: IBulkCancelRequest): Observable<any> {
        this._isLoading$.next(true);

        if (!environment.production) { console.log('REQUEST', request); }

        const url = this.API_URL + '/bulk-cancel';
        return this.http.post<any>(url, request).pipe(
            tap(body => console.log(body)),
            catchError(async err => {

                console.log(err);
                Sentry.captureException(err);

                // Don't propagate error for now
                // return err;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    /**
     * Synch the cancellation with BB platform
     */
    cancel(request: ICancelRequest): Observable<any> {
        return this.bulkCancel({
            reservations: [request]
        });
    }
}
