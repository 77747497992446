import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

export const validateFormGroup = (formGroup: FormGroup | FormArray): void => {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);

        if (control instanceof FormControl) {
            control.markAsDirty();
            control.updateValueAndValidity();
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            validateFormGroup(control);
        } else if (control instanceof FormArray) {
            validateFormGroup(control);
        }
    });
};

export const validateFormControl = (formControl: AbstractControl): void => {
    if (formControl instanceof FormControl) {
        formControl.markAsDirty();
        formControl.updateValueAndValidity();
    }
};
