<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Important warning</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="notification">
    Are you sure? Once deleted, the Event will be deleted and <span class="text-danger">cannot be retrieved</span>. 
    All bookings will be moved to the bookings channel table where you can place them back into a new event.
  </div>
</div>

<div class="modal-footer">
  <ng-container *ngIf="loading | async">
    <span class="spinner spinner-primary ml-5"></span>
  </ng-container>
  <button type="button" class="btn btn-light btn-elevate mr-3" (click)="modal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-danger font-weight-bolder font-size-sm" (click)="onOkSelected()">Ok Delete Event(s)</button>
</div>