import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RESTTableService } from './rest-table.service';

@Injectable({
  providedIn: 'root'
})
export class RESTTableExtendedService extends RESTTableService<any, any> {
  constructor(@Inject(HttpClient) http) {
    super(http);
  }
}
